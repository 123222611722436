'use client';

import { usePathname } from 'next/navigation';
import Script from 'next/script';

type Props = {
  dataDomain?: string;
};

export default function CookieConsent({ dataDomain }: Props) {
  const pathname = usePathname();

  if (pathname?.includes('/preview/')) {
    return null;
  }

  return (
    <>
      {/*
       * Theres a bug pre-next.14.1 that doesn't pass along extra attributes to beforeInteractive scripts. Workaround to use vanilla script for now until we update
       * When updated, switch this back to Script using beforeInteractive strategy
       */}
      <script
        id="oneTrustSDK"
        src="https://cdn.cookielaw.org/scripttemplates/otSDKStub.js"
        data-document-language="true"
        data-domain-script={dataDomain}
        data-dlayer-ignore="true"
        defer
        type="text/javascript"
      />
      <Script
        id="oneTrustOptanon"
        dangerouslySetInnerHTML={{
          __html: `function OptanonWrapper(){}`,
        }}
      />
    </>
  );
}
