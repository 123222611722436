import(/* webpackMode: "eager", webpackExports: ["WithAnalytics"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/components/Analytics/WithAnalytics.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/components/CookieConsent/CookieConsent.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["DraftModeBannerContainer"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/components/DraftMode/Banner.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/components/Layout/Layout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SWRProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/components/SWRProvider/SWRProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AtomicSearchProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/contexts/coveo/AtomicSearchContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CurrencyProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/contexts/CurrencyContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ProductTagsProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/contexts/ProductTagsContext.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AppRouterProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/contexts/Router/AppRouterProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["AuthSessionProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/lib/api/auth/AuthSessionProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["WithAuthSync"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/lib/api/auth/WithAuthSync.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["FlagsProvider"] */ "/home/runner/work/compass-ui/compass-ui/apps/marketplace/src/lib/flags/amplitude/client.tsx");
;
import(/* webpackMode: "eager" */ "/home/runner/work/compass-ui/compass-ui/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.0_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/home/runner/work/compass-ui/compass-ui/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.0_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/google/target.css?{\"path\":\"src/lib/util/getFontVariables.ts\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"],\"variable\":\"--font-inter\",\"weight\":\"variable\"}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/home/runner/work/compass-ui/compass-ui/node_modules/.pnpm/next@14.2.3_@babel+core@7.23.0_@opentelemetry+api@1.8.0_react-dom@18.2.0_react@18.2.0/node_modules/next/font/local/target.css?{\"path\":\"src/lib/util/getFontVariables.ts\",\"import\":\"\",\"arguments\":[{\"src\":[{\"path\":\"../../fonts/Platform-Medium.otf\",\"weight\":\"500\"}],\"variable\":\"--font-platform\"}],\"variableName\":\"platform\"}");
