'use client';
import { TagFragment } from '@graphql/generated-contentful/graphql';
import { PropsWithChildren, createContext, useContext } from 'react';

type ProductTagsType = TagFragment[] | null;

export const ProductTagsContext = createContext<ProductTagsType>(null);

export const ProductTagsProvider = ({
  children,
  value,
}: PropsWithChildren<{
  value: ProductTagsType;
}>) => {
  return (
    <ProductTagsContext.Provider value={value}>
      {children}
    </ProductTagsContext.Provider>
  );
};

// Custom hook to use the context
export const useProductTags = <
  T extends ProductTagsType = ProductTagsType,
>() => {
  const values = useContext(ProductTagsContext);
  return values as T; // Casting to T, where T by default is the type of ProductTagsType
};
